<template>
  <RevCard class="flex flex-col gap-24 p-32">
    <div class="flex">
      <RevIllustration
        alt=""
        class="mr-20"
        :height="56"
        :src="orderline.image"
        :width="56"
      />
      <div class="flex flex-col">
        <h3 class="body-1-bold">{{ orderline.title }}</h3>
        <span class="body-2 mt-1 md:mt-2">
          {{
            i18n(translations.purchaseDate, {
              date: i18n.date(new Date(orderline.purchaseDate)),
            })
          }}
        </span>
      </div>
    </div>

    <InsurancesOptions
      :listing-id="orderline.listingId"
      :offers="offers"
      show-selected
      tracking-zone="insurance_post_purchase"
      @update-compliancy="onSelectedCompliancy"
      @update-selected-option="onSelectedOption"
    />
  </RevCard>
</template>

<script setup lang="ts">
import { checkoutAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import InsurancesOptions from '~/scopes/checkout/components/InsurancesOptions/InsurancesOptions.vue'

import translations from './InsuranceOfferCard.translations'

const props = defineProps<{
  shoppingSessionId: string
  itemId: string
  orderline: checkoutAPI.InsuranceOrderline
  offers: checkoutAPI.InsurancePostPurchaseOffers[]
}>()
const emit = defineEmits(['insurance-option-updated'])

const i18n = useI18n()

const onSelectedOption = async ({
  insurance,
}: {
  insurance: checkoutAPI.InsurancePostPurchaseOffers
}) => {
  await $httpFetch(checkoutAPI.postShoppingSessionSelectInsurance, {
    pathParams: { shoppingSessionId: props.shoppingSessionId },
    body: {
      itemId: props.itemId,
      insuranceOfferId: insurance.id,
    },
  })

  emit('insurance-option-updated')
}

const onSelectedCompliancy = async ({ checked }: { checked: boolean }) => {
  await $httpFetch(checkoutAPI.postShoppingSessionAcceptAgreement, {
    pathParams: { shoppingSessionId: props.shoppingSessionId },
    body: {
      itemId: props.itemId,
      accepted: checked,
    },
  })

  emit('insurance-option-updated')
}
</script>
