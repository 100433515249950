<template>
  <RevCard class="flex flex-col p-24">
    <div class="flex gap-8">
      <div class="grow">
        <Address
          :address="addressModel"
          compact
          with-details
          :with-phone="false"
        >
          <template #title="{ fullName }">
            {{ i18n(translations.billTo, { fullName }) }}
          </template>
        </Address>
      </div>

      <RevButtonTiny variant="secondary" @click="emit('edit-billing')">
        {{ i18n(translations.edit) }}
      </RevButtonTiny>
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { Country, checkoutAPI } from '@backmarket/http-api'
import Address from '@backmarket/nuxt-module-address/Address.vue'
import type { Address as AddressType } from '@backmarket/nuxt-module-address/address'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevCard } from '@ds/components/Card'

import translations from './BillingSummary.translations'

const { address } = defineProps<{
  address: checkoutAPI.InsuranceBilling
}>()

const emit = defineEmits(['edit-billing'])

const i18n = useI18n()

const addressModel = computed<AddressType>(() => {
  return {
    street: address.addressLine1 || '',
    street2: address.addressLine2 || '',
    city: address.city || '',
    company: address.company || '',
    country: address.country as Country,
    firstName: address.firstName || '',
    lastName: address.lastName || '',
    phone: address.phoneNumber || '',
    postalCode: address.postalCode || '',
    stateOrProvince: address.stateOrProvince || '',
    countryDialInCode: null,
  }
})
</script>
