<template>
  <Title />
  <InsuranceOfferCard
    :item-id="shoppingSession.itemId"
    :offers="shoppingSession.insurance.offers"
    :orderline="shoppingSession.insurance.orderline"
    :shopping-session-id="shoppingSessionId"
    @insurance-option-updated="emit('update')"
  />

  <form class="space-y-24" method="POST">
    <UserInformation
      v-model:birthdate="birthdate"
      v-model:birthdate-error="birthdateError"
    />

    <Payment
      :birthdate="birthdate"
      :is-payment-disabled="isPaymentDisabled"
      :shopping-session-id="shoppingSessionId"
      :total-price="shoppingSession.selectedCoverage.totalPrice"
    />
  </form>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { checkoutAPI } from '@backmarket/http-api'

import InsuranceOfferCard from './components/InsuranceOfferCard/InsuranceOfferCard.vue'
import Payment from './components/Payment/Payment.vue'
import Title from './components/Title/Title.vue'
import UserInformation from './components/UserInformation/UserInformation.vue'

const emit = defineEmits(['update'])

const props = defineProps<{
  shoppingSession: checkoutAPI.ShoppingSession
  shoppingSessionId: string
}>()

const birthdate = ref(props.shoppingSession.userInformation.birthdate)
const birthdateError = ref<string | null>(null)

const isPaymentDisabled = computed(() => {
  const {
    isUserAgreementRequired,
    isUserAgreementAccepted,
    isPolicyHolderBirthdateRequired,
  } = props.shoppingSession.selectedCoverage.compliancy

  const isCompliancyChecked = isUserAgreementRequired
    ? isUserAgreementAccepted
    : true

  const isBirthdateValid = isPolicyHolderBirthdateRequired
    ? !!birthdate.value && !birthdateError.value
    : true

  return !isCompliancyChecked || !isBirthdateValid
})
</script>
