<template>
  <h3 class="heading-3">
    {{ i18n(translations.title) }}
  </h3>
  <RevCard>
    <div v-if="paymentErrorMessage" ref="errorMessageBlock" class="flex">
      <RevInfoBlock
        class="mx-20 mb-20 mt-24"
        dismissable
        :icon="IconHeartExploded"
        :title="paymentErrorMessage.title"
        variant="warning"
      >
        <div class="body-2 mt-8">
          <p>{{ paymentErrorMessage.description }}</p>
        </div>
      </RevInfoBlock>
    </div>
    <PaymentForm
      :base-price="totalPrice"
      :create-payment="createPayment"
      :disabled="isPaymentDisabled"
      :payment-methods="paymentMethods || []"
      :resume-payment-id="resumePaymentId"
      @setup-error="handleSetupError"
      @submit-error="handleSubmitError"
      @submit-start="handleSubmitStart"
      @submit-success="handleSubmitSuccess"
      @update:selected-method="handleMethodChange"
    />
  </RevCard>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed, ref } from 'vue'

import { type MonetaryAmount, cartAPI, paymentAPI } from '@backmarket/http-api'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import {
  PaymentForm,
  type PaymentFormCreateFunctionOptions,
  type PaymentReadableMessage,
  type SetupErrorEvent,
  type SubmitErrorEvent,
  type SubmitStartEvent,
  type SubmitSuccessData,
  toCheckoutPaymentCreateBody,
  usePaymentRedirection,
} from '@backmarket/nuxt-module-payment/form'
import { useMarketPaymentMethodsFor } from '@backmarket/nuxt-module-payment/methods'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevCard } from '@ds/components/Card'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconHeartExploded } from '@ds/icons/IconHeartExploded'

import { CHECKOUT } from '~/scopes/checkout/routes-names'
import { FORM_TYPES } from '~/scopes/checkout/types/form'

import translations from './Payment.translations'

const props = defineProps<{
  totalPrice: MonetaryAmount
  shoppingSessionId: string
  isPaymentDisabled: boolean
  birthdate?: string
}>()

const paymentErrorMessage = ref<PaymentReadableMessage | null>(null)
const errorMessageBlock = ref<HTMLInputElement | null>()

const route = useRoute()
const tracking = useTracking()
const i18n = useI18n()

const resumePaymentId = computed(() => route.query.paymentId as string)

const { data: paymentMethods } = await useMarketPaymentMethodsFor({
  bagPrice: () => props.totalPrice,
  hasInsurance: true,
})
const proceedRedirection = usePaymentRedirection({
  paymentResultLocation: (paymentId) => ({
    name: CHECKOUT.PAYMENT_RESULT,
    params: { paymentId },
  }),
})

const createPayment = async (options: PaymentFormCreateFunctionOptions) => {
  const paymentResponse = await $httpFetch(paymentAPI.postCreate, {
    body: {
      ...toCheckoutPaymentCreateBody(options),
      shopping_session_id: props.shoppingSessionId,
    },
  })

  return paymentResponse
}
const showErrorMessage = (message: PaymentReadableMessage) => {
  paymentErrorMessage.value = message
  errorMessageBlock.value?.scrollIntoView({ behavior: 'smooth' })
}

const handleSetupError = async ({ message }: SetupErrorEvent) => {
  showErrorMessage(message)
}

const handleSubmitError = async ({
  redirection,
  message,
}: SubmitErrorEvent) => {
  if (redirection) {
    proceedRedirection(redirection)

    return
  }
  if (message) showErrorMessage(message)
}

const handleSubmitStart = async ({ paymentMethod }: SubmitStartEvent) => {
  paymentErrorMessage.value = null

  tracking.trackClick({
    value: paymentMethod?.bmCode || '',
    name: '4-Payment_cta-pay',
    zone: 'insurance_post_purchase',
  })

  if (!isEmpty(props.birthdate)) {
    await $httpFetch(cartAPI.postUpdateUserInformation, {
      body: { formType: FORM_TYPES.INSURANCE_ONLY, birthdate: props.birthdate },
    })
  }
}

const handleSubmitSuccess = ({ redirection }: SubmitSuccessData) => {
  proceedRedirection(redirection)
}

const handleMethodChange = async (
  method: PaymentMethod | null,
  isSelectedByUser: boolean,
) => {
  paymentErrorMessage.value = null

  if (isSelectedByUser && method?.bmCode) {
    tracking.trackClick({
      name: method?.bmCode,
      zone: 'insurance_post_purchase',
    })
  }
}
</script>
