import { HttpApiError, checkoutAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

type SESSION_KIND = 'INSURANCE_POST_PURCHASE'

export async function useGetCheckoutSession({
  orderlineId,
  sessionKind,
}: {
  orderlineId?: string
  sessionKind: SESSION_KIND
}) {
  const logger = useLogger()

  try {
    if (!orderlineId)
      throw new Error('orderlineId is required to get the session')

    const { shoppingSessionId } = await $httpFetch(
      checkoutAPI.postShoppingSession,
      {
        body: {
          type: sessionKind,
          id: orderlineId,
        },
      },
    )

    return { shoppingSessionId, isExpired: false }
  } catch (error) {
    const httpError = error as HttpApiError

    if (httpError.status === 400) return { isExpired: true }

    logger.error('[Checkout] Unexpected Error creating shopping session', {
      httpError,
    })

    throw new Error('Unexpected Error creating shopping session', {
      cause: error,
    })
  }
}
