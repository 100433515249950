<template>
  <Layout :is-loading="!shoppingSession">
    <template #default>
      <div
        v-if="shoppingSessionId && shoppingSession"
        class="flex flex-col gap-24 md:mb-56"
      >
        <Checkout
          v-if="step === 'checkout'"
          :shopping-session="shoppingSession"
          :shopping-session-id="shoppingSessionId"
          @update="refreshShoppingSession"
        />
        <BillingAddress
          v-if="step === 'billing'"
          :address="shoppingSession.billingAddress"
          @post-address-success="handlePostAddressSuccess"
        />
      </div>
    </template>

    <template #aside>
      <div v-if="shoppingSession" class="space-y-16">
        <div class="heading-3">{{ i18n(translations.summaryTitle) }}</div>

        <CoverageSummary
          :selected-coverage="shoppingSession.selectedCoverage"
        />
        <BillingSummary
          :address="shoppingSession.billingAddress"
          @edit-billing="step = 'billing'"
        />
      </div>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { navigateTo, useHead, useRoute } from '#imports'
import { ref } from 'vue'

import { checkoutAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { useGetCheckoutSession } from '~/scopes/checkout/composables/useGetCheckoutSession'
import Layout from '~/scopes/insurance/components/Layout/Layout.vue'

import { INSURANCE } from '../../routes.constants'

import translations from './PostPurchase.translations'
import BillingAddress from './components/BillingAddress/BillingAddress.vue'
import BillingSummary from './components/BillingSummary/BillingSummary.vue'
import Checkout from './components/Checkout/Checkout.vue'
import CoverageSummary from './components/CoverageSummary/CoverageSummary.vue'

const route = useRoute()
const i18n = useI18n()

useHead({
  title: i18n(translations.pageTitle),
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: i18n(translations.description),
    },
  ],
})

const step = ref<'checkout' | 'billing'>('checkout')

let shoppingSessionId: string | undefined
let isExpired

// Wrapping in try catch for now since for some reason the session POST always fails in the server
// To be removed once 403 issue is resolved
try {
  const orderlineId = route.query.orderlineId?.toString()

  ;({ shoppingSessionId, isExpired } = await useGetCheckoutSession({
    orderlineId,
    sessionKind: 'INSURANCE_POST_PURCHASE',
  }))

  if (isExpired) navigateTo({ name: INSURANCE.EXPIRED })
} catch {
  /* empty */
}

const { data: shoppingSession, refresh: refreshShoppingSession } =
  await useHttpFetch(checkoutAPI.getShoppingSession, {
    pathParams: { sessionId: shoppingSessionId },
  })

const handlePostAddressSuccess = () => {
  step.value = 'checkout'

  refreshShoppingSession()
}
</script>
