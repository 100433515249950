<template>
  <SummaryCard
    :subtotal="selectedCoverage.subTotalPrice"
    :tax="selectedCoverage.taxes"
    :total="selectedCoverage.totalPrice"
  >
    <template #default>
      <div class="mb-8 flex gap-16">
        <RevIllustration
          alt="UP logo"
          class="self-start"
          :height="48"
          src="/img/insurances/upLogo.svg"
          :width="48"
        />
        <div class="flex flex-col gap-8">
          <h3 class="body-2-bold">{{ selectedCoverage.title }}</h3>
          <span class="body-2">
            {{
              i18n(translations.forProduct, {
                model: selectedCoverage.product.title,
              })
            }}
          </span>
        </div>
      </div>

      <RevDivider class="space-y-24" />

      <div class="body-2 flex justify-between">
        <span>{{ i18n(translations.coverageBegins) }}</span>
        <span>{{ i18n.date(new Date(selectedCoverage.startDate)) }}</span>
      </div>
      <div class="body-2 flex justify-between">
        <span>{{ i18n(translations.expires) }}</span>
        <span>{{ i18n.date(new Date(selectedCoverage.endDate)) }}</span>
      </div>
    </template>
  </SummaryCard>
</template>

<script setup lang="ts">
import { checkoutAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'

import SummaryCard from '~/scopes/insurance/components/SummaryCard/SummaryCard.vue'

import translations from './CoverageSummary.translations'

defineProps<{ selectedCoverage: checkoutAPI.SelectedCoverage }>()

const i18n = useI18n()
</script>
